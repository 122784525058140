<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ form.title }}
              </h2>
            </div>
          </div>
        </div>
        <!-- <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              href="request-forms-list.php"
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back"
            ><i data-feather="chevron-left" /></a>
          </div>
        </div> -->
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <!-- title section -->
            <div class="card card__request__form">
              <div class="card-content">
                <div class="card-body">
                  <h1 class="cart-title text-success">
                    Form submitted successfully
                  </h1>
                  <div class="mb-1">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut autem ipsam delectus. Provident sint, inventore ut animi perspiciatis commodi non minus ratione consectetur ad dolores id sed voluptatibus dolore veniam.</p>
                    <hr>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      form: 'requestForms/item',
    }),
  },
  mounted() {

  },
  methods: {
  },
}
</script>
